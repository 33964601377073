import "./app.css";

import * as Sentry from "@sentry/react";

import { Box, Container, Grid } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import Disclaimer from "./components/disclaimer";
import { FAQ } from './pages/faq';
import Loading from "./components/loadingScreen";
import PageLayout from "./layouts/page-layout";
import ProtectedRoute from "./components/protected-route/protected-route";
import Rainfall from "./pages/rainfaill";
import UserAdminPage from "./pages/admin/user/user";
import logUserActivity from "./common/user-activity-logging";
import { useAuth } from "./components/auth-provider/auth-provider";
import useUnload from "./hooks/useUnload";
import { userRoles } from "./lib/user-roles";

function App() {
  const { loading, user } = useAuth();

  useUnload(e => {
    logUserActivity({ action: 'APP_CLOSE' })
    e.preventDefault();
  });

  const defaultRoute = '/';

  return (
    <Box>
      <CssVarsProvider>
        <Disclaimer></Disclaimer>
        <Container>
          <Grid gap={3} paddingTop={10} justifyContent="center" container>
            <Grid xs={12} item>
              {!loading ? (
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="faq"
                      element={
                        <PageLayout
                          page={<FAQ />}
                          title={"Frequently asked questions"}
                        ></PageLayout>
                      }
                    ></Route>
                    <Route
                      path="/"
                      element={
                        <PageLayout
                          page={<Rainfall />}
                          title={"Rainfall"}
                        ></PageLayout>
                      }
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to={defaultRoute}></Navigate>}
                    ></Route>
                  </Routes>
                </BrowserRouter>
              ) : (
                <Loading />
              )}
            </Grid>
          </Grid>
        </Container>
      </CssVarsProvider>
    </Box>
  );
}

export default Sentry.withProfiler(App);
