import {
  Box,
  Button,
  Link as MaterialLink,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { NestedMenuItem } from 'mui-nested-menu';
import { disclaimerOpenAtom } from "../state/recoil";
import { useAuth } from "../components/auth-provider/auth-provider";
import { useRecoilState } from "recoil";
import { useState } from "react";
import { userRoles } from "../lib/user-roles";

const PageLayout = ({ page, title, ...props }) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [disclaimerOpen, setDisclaimerOpen] = useRecoilState(disclaimerOpenAtom);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={3}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          marginBottom={3}
        >
          <Typography variant="h2">{title}</Typography>

          <Button onClick={handleClick}>
            <MenuIcon fontSize="large"></MenuIcon>
          </Button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>

            <MaterialLink component={Link} to={"/"} underline="none">
              <MenuItem>Rainfall</MenuItem>
            </MaterialLink>

            <MaterialLink
              component={Link}
              to={"/faq"}
              underline="none">
              <MenuItem>FAQ</MenuItem>
            </MaterialLink>

            <MaterialLink
              onClick={() => {
                if (!disclaimerOpen) {
                  setDisclaimerOpen(true);
                }
              }}
              underline="none"
            >
              <MenuItem>Terms and Conditions</MenuItem>
            </MaterialLink>

          </Menu>
        </Box>
        {page}
      </Box>
    </Box>
  );
};

export default PageLayout;
