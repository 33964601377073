import "dygraphs/dist/dygraph.css";

import { useCallback, useEffect, useRef, useState } from "react";

import Dygraph from "dygraphs";
import { colors } from "@mui/material";
import config from "../../lib/config";
import { dateWindowState } from "../../state/recoil";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

const guidesConfig = {
  ground: {
    name: 'Ground level',
    lineDash: [],
    color: config.colors.groundLevel
  },
  screenTop: {
    name: 'Screen top',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
  screenBot: {
    name: 'Screen base',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
}

const getMax = (arr) => {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
}

const getMin = (arr) => {
  let len = arr.length;
  let min = Infinity;

  while (len--) {
    min = arr[len] < min ? arr[len] : min;
  }
  return min;
}

export const DygraphComponent = ({
  dataType,
  series,
  pointClickCallback,
  yTitle,
  guides=[],
  invertY,
  selectedDataPoint,
  pointsWithComments=[],
  pointsWithAnnotations=[],
  defaultDateWindow,
  visibleSeries,
}) => {
  const myRef = useRef();

  const [dateWindow, setDateWindow] = useRecoilState(dateWindowState(dataType));

  const mergedSeries = useMemo(() => {
    const dates = {};

    series.map((item, index) => {
      item.data.map((point) => {
        if (dates[point.x]) {
          dates[point.x][index] = point;
        } else if (!isNaN(Date.parse(point.x))) {
          const row = Array(series.length).fill(null);
          row[index] = point;
          dates[point.x] = row;
        }
      });
    });

    const labels = ["Date", ...series.map((item) => item.name)];

    const data = Object.keys(dates).map((key) => [
      new Date(key),
      ...dates[key],
    ]).sort((a, b) => a[0] - b[0]);

    const seriesData = data.map(series => series.map((data, i) => i === 0 ? data : data && Number(data.y)));

    return {
      labels,
      data,
      seriesData,
    };
  }, [series]);

  useEffect(() => {
    setDateWindow(defaultDateWindow || undefined);
  }, [series]);

  const valueRange = useMemo(() => {
    const values = mergedSeries.seriesData
      ?.map((item) => item.slice(1))
      .flat()
      .filter(Number.isFinite);
    const guidesValues = guides.map((m) => m.value).filter(Number.isFinite);
    let max = getMax([...values, ...guidesValues]);
    let min = getMin([...values, ...guidesValues]);

    if (Math.abs(max - min) < 5) {
      max = max + 2;
    }
    return invertY ? [max, min] : [min, max];
  }, [guides, invertY, mergedSeries.seriesData]);

  useEffect(
    (node) => {
      if (myRef.current && series) {
        const graph = new Dygraph(myRef.current, mergedSeries.seriesData, {
          colors: config.colors.series,
          visibility: visibleSeries.map((m) => m.visible),
          labels: mergedSeries.labels,
          width: "100%",
          height: 600,
          connectSeparatedPoints: true,
          drawPoints: true,
          pointClickCallback: (event, point) => {
            const pointData = {
              id: mergedSeries.data[point.idx][1].id,
              series: point.name,
            };
            pointClickCallback(pointData);
          },
          
          strokeWidth: 1,
          pointSize: 3,
          labelsUTC: true,
          yRangePad: 50,
          xRangePad: 50,
          ylabel: yTitle,
          legend: "follow",
          legendFormatter: (data) => {
            let string = `<b>${data.xHTML}</b> `;
            data.series.forEach((s) => {
              if (s.y !== undefined) {
                string = `<b>${(new Date(data.x)).toLocaleString()}</b> <b style="color:${s.color}">${s.labelHTML}</b> : ${s.yHTML}<br/>`;
              }
            });
            return `<div style="width: 225px;border: 1px solid gray; border-radius: 4px; padding: 10px">${string}</div>`;
          },
          y2label: "Rainfall mm/day",
          xlabel: "Date",
          yLabelWidth: 25,
          xLabelHeight: 25,
          valueRange,
          zoomCallback: (minDate, maxDate, yRange) => {
            setDateWindow([minDate, maxDate]);
          },
          drawPointCallback: (
            g,
            seriesName,
            ctx,
            cx,
            cy,
            color,
            pointSize,
            idx
          ) => {
            ctx.fillStyle = color;
            if (
              selectedDataPoint &&
              selectedDataPoint.series === seriesName &&
              +selectedDataPoint.date === +mergedSeries.seriesData[idx][0]
            ) {
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize, 0, 2 * Math.PI);
              ctx.stroke();
              ctx.fill();
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize + 3, 0, 2 * Math.PI);
              ctx.lineWidth = 3;
              ctx.stroke();
            } else {
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize, 0, 2 * Math.PI);
              if (seriesName !== 'Rainfall' && !mergedSeries.data[idx][1]?.is_grouped) {
                ctx.fill();
              }
              ctx.stroke();
            }
          },
          dateWindow: dateWindow || defaultDateWindow,
          interactionModel: {
            ...Dygraph.defaultInteractionModel,
            dblclick: (e, g, context) => {
              setDateWindow(defaultDateWindow);
              g.updateOptions({
                dateWindow: defaultDateWindow,
                valueRange,
              });
            },
          },
          underlayCallback: function (ctx, area, g) {
            guides?.forEach((item, index) => {
              const coordY = g.toDomYCoord(item.value);
              // text
              ctx.fillStyle = guidesConfig[item.name].color;
              ctx.fillText(guidesConfig[item.name].name, area.x + 5 + index * 75, coordY - 5);
              // line
              ctx.beginPath();
              ctx.setLineDash(guidesConfig[item.name].lineDash);
              ctx.moveTo(0, coordY);
              ctx.lineTo(ctx.canvas.getBoundingClientRect().right, coordY);
              ctx.strokeStyle = guidesConfig[item.name].color;
              ctx.stroke();
              ctx.closePath();
              ctx.setLineDash([]);
            });
          },
          series: {
            Rainfall: {
              axis: "y2",
            },
          },
          axes: {
            y: {
              axisLabelWidth: 80,
            },
            y2: {
              independentTicks: true,
            },
          },
        });
        graph.ready(() => {
          graph.setAnnotations(
            [
              ...pointsWithAnnotations.map((point) => {
              return {
                series: point.series,
                x: +new Date(point.x),
                cssClass: "dygraphsAnnotation",
                shortText: `${point.unit || "n/a"}`,
                text: `${point.unit || "n/a"}`,
              };
            }),
              ...pointsWithComments.map((point) => {
              return {
                series: point.series,
                x: +new Date(point.x),
                cssClass: "dygraphsCommentAnnotation",
                shortText: `${point.openCommentCount || "✓"}(${
                  point.commentCount
                })`,
                text: `Open comments: ${point.openCommentCount},  Total comments: ${point.commentCount}`,
              };
            }),
          ]
          );
        });
      }
    },
    [
      guides,
      mergedSeries.seriesData,
      mergedSeries.labels,
      series,
      pointClickCallback,
      valueRange,
      yTitle,
      selectedDataPoint,
      pointsWithComments,
      pointsWithAnnotations,
      dateWindow,
      setDateWindow,
    ]
  );
  return <div ref={myRef}></div>;
};
