import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  dateWindowState,
  selectedDataPointFamily,
  visibleSeriesState,
} from "../../state/recoil";

import { DygraphComponent } from "../dygraphs/dygraphs";
import HomeIcon from "@mui/icons-material/Home";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Stack } from "@mui/system";
import config from "../../lib/config";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

const GraphTooltip = (
  <Stack divider={<hr />}>
    <Typography variant="caption">
      Mouse over to highlight individual values.
    </Typography>
    <Typography variant="caption">
      Select an area to zoom in by clicking and dragging.
    </Typography>
    <Typography variant="caption">
      Pan by holding shift and dragging with a mouse.
    </Typography>
    <Typography variant="caption">
      Reset zoom by double clicking or clicking Home button.
    </Typography>
    <Typography variant="caption">
      Click on a value to open comments. (only allowed when graph type is set to
      All Readings)
    </Typography>
  </Stack>
);

const SeriesChart = ({
  dataType,
  data,
  isReversed,
  guides,
  min,
  max,
  yTitle,
  canSelectPoint,
  pointsWithComments,
  pointsWithAnnotations = [],
}) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );

  const [visibleSeries, setVisibleSeries] = useRecoilState(
    visibleSeriesState(dataType)
  );

  if (visibleSeries.length != data.length) {
    setVisibleSeries(data.map((d) => ({ name: d.name, visible: true })));
  }

  const [dateWindow, setDateWindow] = useRecoilState(dateWindowState(dataType));

  const defaultDateWindow = useMemo(() => {
    if (data.length === 1 && data[0].data.length === 1) {
      // this is needed because dygraphs doesn't like it when the date window is the same as the data point
      return [
        +new Date(data[0].data[0].x) - 1000000000,
        +new Date(data[0].data[0].x) + 1000000000,
      ];
    }
    return undefined;
  }, [data]);

  return (
    <>
      {(data.length && (
        <Card>
          <CardContent>
            <Stack
              paddingX={2}
              paddingY={2}
              justifyContent={"flex-end"}
              direction="row"
            >
              <IconButton
                onClick={() => {
                  setDateWindow(defaultDateWindow);
                }}
              >
                <HomeIcon></HomeIcon>
              </IconButton>

              <Tooltip title={GraphTooltip}>
                <IconButton>
                  <QuestionMarkIcon></QuestionMarkIcon>
                </IconButton>
              </Tooltip>
            </Stack>
            <DygraphComponent
              dataType={dataType}
              min={min}
              max={max}
              yTitle={yTitle}
              defaultDateWindow={defaultDateWindow}
              invertY={isReversed}
              series={data}
              guides={guides}
              // hack to prevent errors when the visible series is not the same length as the data during initial render
              visibleSeries={
                visibleSeries.length === data.length
                  ? visibleSeries
                  : data.map((d) => ({ name: d.name, visible: true }))
              }
              pointsWithComments={pointsWithComments}
              pointsWithAnnotations={pointsWithAnnotations}
              selectedDataPoint={selectedDataPoint}
              pointClickCallback={(pointData) => {
                if (!canSelectPoint || pointData.series === "Rainfall") {
                  return;
                }

                const series = data.find((d) => d.name === pointData.series);
                const originalPoint = series.data.find((p) => p.id === pointData.id);

                setSelectedDataPoint({
                  id: originalPoint.id,
                  boreholeId: series.name.split("#")[0],
                  boreholePipeId: series.name,
                  series: series.name,
                  date: new Date(originalPoint.x),
                  value: originalPoint.y,
                  threads: originalPoint.threads
                });
              }}
            />
            <Box marginTop={3} sx={{ textAlign: "center" }}>
              {visibleSeries.map((s, index) => {
                return (
                  <Box
                    key={s.name}
                    marginLeft={3}
                    sx={{ display: "inline-block" }}
                  >
                    <Typography
                      onClick={() => {
                        // copy array to unfreeze it
                        const newSeries = JSON.parse(
                          JSON.stringify(visibleSeries)
                        );
                        newSeries[index].visible = !newSeries[index].visible;
                        setVisibleSeries(newSeries);
                      }}
                      sx={{
                        cursor: "pointer",
                        textDecoration: !s.visible && "line-through",
                      }}
                      variant="caption"
                    >
                      <div
                        className="series-color"
                        style={{
                          backgroundColor: config.colors.series[index],
                          height: "10px",
                          width: "10px",
                          display: "inline-block",
                          marginRight: "5px",
                          borderRadius: "50%",
                        }}
                      ></div>
                      {s.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default SeriesChart;
