import * as Sentry from "@sentry/react";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Suspense, useState } from "react";
import {
  rainfallSeries,
  rainfallStations,
  selectedRainfallStations,
  selectedRainfallStationsSelector
} from "../state/recoil";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable
} from "recoil";

import { ErrorBoundary } from "../components/errorBoundary/errorBoundary";
import SeriesGraph from "../components/SeriesGraph";
import api from "../lib/api";
import config from "../lib/config";
import featureFlags from "../feature-flag.json";

const StationSelect = () => {
  const stations = useRecoilValueLoadable(rainfallStations);
  const [selectedStations, setSelectedStations] = useRecoilState(
    selectedRainfallStationsSelector
  );

  const handleAutocompleteChanged = async (_, value) => {
    if (value !== null) {
      setSelectedStations(value);
    }
  };

  return (
    <Autocomplete
      sx={{ userSelect: "unset" }}
      multiple={true}
      disablePortal
      value={selectedStations}
      options={stations.getValue()}
      getOptionLabel={(option) => (option ? option.stationReference : "")}
      renderInput={(params) => (
        <TextField {...params} label="Rainfall station" />
      )}
      onChange={handleAutocompleteChanged}
    />
  );
};

const FindNearestStation = () => {
  const [selectedStations, setSelectedStations] = useRecoilState(
    selectedRainfallStationsSelector
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmitted = async (value) => {
    if (value.lat && value.long) {
      setIsLoading(true);
      try {
        const stationNearby = (await (api.get('rainfall/station/nearby', { params: { lat: value.lat, long: value.long } }))).data;
        if (stationNearby) {
          setSelectedStations([...selectedStations, ...stationNearby.map(station => ({ ...station, selectedLat: value.lat, selectedLong: value.long, ...stationNearby }))]);
        }
      } catch (err) {
        if (featureFlags.sentry) {
          Sentry.captureException(err);
        }
        setIsLoading(true);
      }
      setIsLoading(false);
    }
  }

  return (

    <Card>
      <CardContent>
        <Box>
          <FormContainer onSuccess={handleFormSubmitted}>
            <Grid container>
              <FormGroup row className="items-center">
                <FormControl sx={{ m: 1 }}>
                  <TextFieldElement
                    label={'Latitude'}
                    name={'lat'}
                    required
                    margin={'dense'}
                    mask="^(-?\d{1,2}.\d{6}),(\s*)(-?\d{1,3}.\d{6})$"
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                  <TextFieldElement
                    label={'Longitude'}
                    name={'long'}
                    required
                    margin={'dense'}
                    mask="[-]9{1,2}.9{6}"
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                  >
                    Find {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
                  </Button>
                </FormControl>
              </FormGroup>
            </Grid>
          </FormContainer>
        </Box>
      </CardContent >
    </Card >
  );
}

const Stations = () => {
  const stations = useRecoilValue(selectedRainfallStations);

  if (stations && stations.length) {
    return (
      <div>
        {
          stations.map((station, index) => (
            <Card className="mt-1" key={station.stationReference + index}>
              <CardContent>
                <Typography>
                  <strong>EA ID:</strong> <span style={{ color: config.colors.series[index] }}>{station.stationReference}</span>
                  <br />
                  <strong>Lat:</strong> {station.lat}
                  <br />
                  <strong>Long:</strong> {station.long}
                  {station.selectedLat && (<span><br /><strong>Selected location lat:</strong> {station.selectedLat}</span>)}
                  {station.selectedLong && (<span><br /><strong>Selected location long:</strong> {station.selectedLong}</span>)}
                  {(station.distance || station.distance === 0) && (<span><br /><strong>Distance to selected location:</strong> {(station.distance / 1000).toFixed(3)} km</span>)}
                </Typography>
              </CardContent>
            </Card>
          ))
        }
      </div>
    );
  }
};

const Graph = () => {
  const data = useRecoilValue(rainfallSeries);

  if (data && data.length && data.find(series => series.data && series.data.length)) {
    return (
      <SeriesGraph
        dataType={"rainfall"}
        data={data}
        yTitle={`Precipitation in mm`}
        canSelectPoint={false}
      ></SeriesGraph>
    );
  } else {
    return <></>;
  }
};

const Rainfall = () => {
  return (
    <Box>
      <Box>
        <Card>
          <CardContent>
            <Suspense fallback="Loading ...">
              <StationSelect />
            </Suspense>
          </CardContent>
        </Card>
      </Box>
      <Box marginTop={1}>
        <Suspense>
          <FindNearestStation />
        </Suspense>
      </Box>
      <Box marginTop={3}>
        <Suspense>
          <Stations />
        </Suspense>
      </Box>
      <Box marginTop={3}>
        <ErrorBoundary>
          <Suspense fallback="Loading rainfall data...">
            <Graph />
          </Suspense>
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default Rainfall;
