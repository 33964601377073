import { Stack } from "@mui/system";
import { Typography } from "@mui/material";

export function FAQ() {
  return (
    <Stack spacing={2}>
      <Typography fontWeight={"bold"}>
        Q) How do I zoom out completely/reset the zoom?
      </Typography>
      <Typography>
        A) the Home button on the graph will reset your zoom to a fully zoomed
        out state.
      </Typography>
    </Stack>
  );
}
