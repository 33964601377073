import React, { createContext, useState, useContext, useEffect } from "react";
import { useRecoilState } from "recoil";
import logUserActivity from "../../common/user-activity-logging";
import api from "../../lib/api";
import { disclaimerOpenAtom } from "../../state/recoil";

const AuthContext = createContext({
  isAuthenticated: false,
  user: {},
  login: () => {},
  loading: true,
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setOpen] = useRecoilState(disclaimerOpenAtom);

  useEffect(() => {
    async function loadUserFromLS() {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        api.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
        try {
          const { data: user } = await api.get("auth/me");
          if (user) setUser(user);
        } catch (e) {
          console.log(e.message);
          logout();
        }
      }
      setLoading(false);
    }
    loadUserFromLS();
  }, []);

  const login = async (email, password) => {
    try {
      const {
        data: { access_token: accessToken },
      } = await api.post("auth/login", { email, password });
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        api.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
        const { data: user } = await api.get("auth/me");
        setUser(user);
        setOpen(true);

        return user;
      }
    } catch (e) {
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    setUser(null);
    logUserActivity({ action: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, login, loading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
