import { colors } from "@mui/material";

const config = {
  appVersion: process.env.REACT_APP_APP_VERSION || "dev",
  clientName: process.env.REACT_APP_CLIENT_NAME || "HS2",
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  API_URL: process.env.REACT_APP_API_URL || "http://localhost:3000",
  sentryDSN:
    process.env.SENTRY_DSN ||
    "https://d014d4f1b87d49aaba06e86ad54025e4@glitchtip-wxi5p.ondigitalocean.app/10",
  sentryTracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE || 1.0,
  colors: {
    line: colors.blue[500],
    groundLevel: colors.grey[600],
    pipe: colors.blueGrey[400],
    gray: colors.grey[500],
    series: [
      "#b90094",
      "#7b60d9",
      "#7080dd",
      "#659cdc",
      "#58b7d8",
      "#43d2d1",
      "#00edc9",
      "#d9df2f",
      "#e7bd3f",
      "#f09b47",
      "#f47549",
      "#f44844",
      "#e9002c",
    ],
  },
  chemistryDeterminantLabelMap: {
    pH: "pH",
    "pH : In Situ": "pH",
    "pH by Meter": "pH",
    "pH (w)": "pH",
  },
};

export default config;
