import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useRef } from "react";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Feature } from 'flagged';
import Typography from "@mui/material/Typography";
import config from "../../../lib/config";
import logo from "./logo.jpg";
import styles from "../disclaimer.module.css";
import { useAuth } from "../../auth-provider/auth-provider";

export default function HS2Disclaimer({ open, appInfo, agreed }) {
  const { user } = useAuth();
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, appInfo, agreed]);

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString();
    }
  }

  return (
    <DialogContent dividers={true}>
      <DialogContentText
        component={"div"}
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
      >
        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          <img
            style={{ display: "block", margin: "0 auto 16px auto", maxHeight: "200px" }}
            src={logo}
            alt={"logo"}
          ></img>
          <p style={{ marginTop: 0 }}>
            This hydrological data application is a visualisation tool for the rainfall precipitation data.
          </p>
          <p>
            Prior to use of this data visualisation tool please agree to the
            below general terms of service:
          </p>
          <p>
            <ol start="1" type="1">
              <li>
                All data supplied is delivered “as is”. The Information is
                licensed 'as is' and the Information Provider and/or Licensor
                excludes all representations, warranties, obligations and
                liabilities in relation to the Information to the maximum
                extent permitted by law.
              </li>
              <li>
                The Information Provider and/or Licensor are not liable for
                any errors or omissions in the information and shall not be
                liable for any loss, injury or damage of any kind caused by
                its use. The Information Provider does not guarantee the
                continued supply of the Information.
              </li>
            </ol>
          </p>
          <p>
            The application uses Environment
            Agency rainfall data from the real-time data API (Beta).
            This is provided as open data under the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
            >
              Open Government Licence.
            </a>
          </p>

          <p>
            <div>
              <ol style={{ listStyle: "none" }}>
                <li>
                  API version No.: <strong>{appInfo.api_version}</strong>
                </li>
                <li>
                  Dashboard version No.: <strong>{config.appVersion}</strong>
                </li>
              </ol>
            </div>
          </p>
        </Typography>

        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          <p>Credits:</p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <a href="https://siorconsulting.com/">
                S&iacute;or Consulting Ltd
              </a>
            </li>
          </ul>
          {agreed !== true &&
            <p>
              Click <strong>AGREE</strong> to view the dashboard, by clicking
              here, you are agreeing to the terms of service.
            </p>
          }
        </Typography>
      </DialogContentText>
    </DialogContent>
  )
}
