import React, {useRef} from "react";
import { agreedToTermsAtom, appInfoState, disclaimerLoadingAtom, disclaimerOpenAtom } from "../../state/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import HS2Disclaimer from "./HS2/dialog-content";
import config from '../../lib/config'
import loadable from '@loadable/component'
import styles from "./disclaimer.module.css";
import { useAuth } from "../auth-provider/auth-provider";
import { useEffect } from "react";

export default function Disclaimer() {
  const [isOpen, setOpen] = useRecoilState(disclaimerOpenAtom);
  const [isLoading, setIsLoading] = useRecoilState(disclaimerLoadingAtom);
  const appInfo = useRecoilValue(appInfoState);
  const [isAgreed, setAgreed] = useRecoilState(agreedToTermsAtom);
  const { isAuthenticated, loading } = useAuth();

  useEffect(() => {
    if (appInfo) {
      setIsLoading(false);
    }
  }, [appInfo]);

  useEffect(() => {
    if (!isOpen && !isAgreed && isAuthenticated) {
      setOpen(true);
    }
  }, [isAgreed, loading, appInfo, isAuthenticated, isOpen, isLoading]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const DialogContent = HS2Disclaimer;

  // const DialogContent = loadable(() => import(`./${config.clientName}/dialog-content.jsx/?v=${config.appVersion}`));
  // DialogContent.load().then(() => {
  //   setIsLoading(false);
  // });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setAgreed(true);
      sessionStorage.setItem("agreedToTerms", "true");
      setOpen(false);
    }
  };

  return (
    <div>
      {isLoading ? "" : <Dialog
        className={styles.disclaimer}
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent open={isOpen} appInfo={appInfo} agreed={isAgreed}></DialogContent>
        <DialogActions>
          <Button onClick={handleClose} ref={descriptionElementRef}>
            <strong>{isAgreed ? 'Close' : 'Agree'}</strong>
          </Button>
        </DialogActions>
      </Dialog>}
    </div>
  );
}
